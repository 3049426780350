/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {KTCardBody} from '../../../../_metronic/helpers'
import CustomPagination from '../../../../_metronic/partials/componants/Pagination'
import {
  addNewCampaignInAdsAnalytics,
  updateCampaignInAdsAnalytics,
  getAllAdsAnalyticsList,
  updateAllROASinAdsCampaign,
} from '../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../utils/ToastUtils'
import clsx from 'clsx'
import {DateWithTimeFormatter} from '../../../../utils/DateUtils'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import CampaignAdsAnalytics from './campaignAdsAnalytics'
import {Dropdown} from 'react-bootstrap'
import {CustomToggle} from '../../../../_metronic/partials/componants/CustomToggle'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Ads Analytics',
    path: 'admin/user-verification',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AdsAnalytics = () => {
  const [adsAnalyticsList, setAdsAnalyticsList] = useState<any>([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const [isViewMode, setIsViewMode] = useState(false)
  const [newCampaign, setNewCampaign] = useState<any>({
    campaignName: '',
    platform: '',
    budgetType: '',
    campaignType: '',
    devicePlatform: '',
    campaignSource: '',
  })

  const [selectedAdsCampaign, setSelectedAdsCampaign] = useState<any>({
    campaignName: '',
    platform: '',
    budgetType: '',
    campaignType: '',
    devicePlatform: '',
    campaignSource: '',
    status: true,
  })

  useEffect(() => {
    getAdsAnalyticsList(page, pageSize)
  }, [])

  const getPagination = (page: any, pageSize: any) => {
    if (page === 0 || page === 1) {
      page = 1
    }
    setPage(page)
    getAdsAnalyticsList(page, pageSize)
  }

  const getAdsAnalyticsList = async (page: any, pageSize: any) => {
    let result = await getAllAdsAnalyticsList(page, pageSize)
    if (result.status === 200) {
      setAdsAnalyticsList(result.data)
      setTotalPage(result.totalPage)
    } else {
      ErrorToastUtils()
    }
  }

  const handleAddCampaign = async () => {
    let result = await addNewCampaignInAdsAnalytics(newCampaign)
    if (result.status === 200) {
      getAdsAnalyticsList(page, pageSize)
      ToastUtils({
        type: 'success',
        message: 'campaign add successfully',
      })
      setNewCampaign({
        campaignName: '',
        platform: '',
        budgetType: '',
        campaignType: '',
        devicePlatform: '',
        campaignSource: '',
      })
    } else {
      ErrorToastUtils()
    }
  }

  const handleUpdateCampaign = async () => {
    let result = await updateCampaignInAdsAnalytics(selectedAdsCampaign)
    if (result.status === 200) {
      getAdsAnalyticsList(page, pageSize)
      ToastUtils({
        type: 'success',
        message: 'campaign Update successfully',
      })
      setSelectedAdsCampaign({
        campaignName: '',
        platform: '',
        budgetType: '',
        campaignType: '',
        devicePlatform: '',
        campaignSource: '',
        status: true,
      })
    } else {
      ErrorToastUtils()
    }
  }

  const handleUpdateROASCampaign = async () => {
    let result = await updateAllROASinAdsCampaign()
    if (result.status === 200) {
      getAdsAnalyticsList(page, pageSize)
      ToastUtils({
        type: 'success',
        message: 'campaign Update successfully',
      })
    } else {
      ErrorToastUtils()
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Ads Analytics</PageTitle>

      {isViewMode ? (
        <CampaignAdsAnalytics
          selectedAdsCampaign={selectedAdsCampaign}
          isViewMode={isViewMode}
          setIsViewMode={setIsViewMode}
        />
      ) : (
        <>
          <div className='d-flex justify-content-end gap-3'>
            <div className='d-flex justify-content-end'>
              <button className='btn btn-primary mb-2' onClick={handleUpdateROASCampaign}>
                Update ROAS
              </button>
            </div>
            <div className='d-flex justify-content-end'>
              <button
                className='btn btn-primary mb-2'
                data-bs-toggle='modal'
                data-bs-target='#add_campaign'
              >
                Add Campaign
              </button>
            </div>
          </div>
          <KTCardBody className='py-4 card'>
            <div className='table-responsive'>
              <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    <td>Cam. ID</td>
                    <td>Platform</td>
                    <td>Campaign Name</td>
                    <td style={{width: '100px'}}>Campaign Link</td>
                    <td>Budget</td>
                    <td>Budget Type</td>
                    <td>Status</td>
                    <td>Cam. Type</td>
                    <td>Cam. Source</td>
                    <td>Device Platform</td>
                    <td>Imp</td>
                    <td>Clicks</td>
                    <td>CTR</td>
                    <td>CPC</td>
                    <td>Install</td>
                    <td>CPI</td>
                    <td>ROAS</td>
                    <td>Revenue</td>
                    <td>Date</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody className='text-gray-600 '>
                  {adsAnalyticsList.length !== 0 &&
                    adsAnalyticsList.map((adsAnalytics: any, index: any) => {
                      let CTR = adsAnalytics?.impression
                        ? (adsAnalytics.clicks * 100) / adsAnalytics.impression
                        : 0

                      CTR = parseFloat(CTR.toFixed(2))

                      let CPC = adsAnalytics?.clicks ? adsAnalytics.budget / adsAnalytics.clicks : 0

                      CPC = parseFloat(CPC.toFixed(2))

                      let CPI = adsAnalytics?.budget
                        ? adsAnalytics?.budget / adsAnalytics?.installs
                        : 0

                      CPI = parseFloat(CPI.toFixed(2))

                      let ROAS =
                        adsAnalytics?.roas && adsAnalytics?.budget > 0
                          ? adsAnalytics.roas / adsAnalytics.budget
                          : 0

                      ROAS = parseFloat(ROAS.toFixed(2))

                      return (
                        <tr key={index}>
                          <td>
                            <span>{adsAnalytics?.campaignId || ' - '}</span>
                          </td>
                          <td>
                            <span>{adsAnalytics?.platform || ' - '}</span>
                          </td>
                          <td>
                            <span>{adsAnalytics?.campaignName || ' - '}</span>
                          </td>
                          <td style={{maxWidth: '100px'}}>
                            <CopyToClipboard
                              text={adsAnalytics?.campaignLink}
                              onCopy={() => {
                                ToastUtils({type: 'success', message: 'Copied SuccessFully'})
                              }}
                            >
                              <span className='cursor-pointer'>
                                {adsAnalytics?.campaignLink || ' - '}
                              </span>
                            </CopyToClipboard>
                          </td>
                          <td>
                            <span>{'₹ ' + adsAnalytics?.budget}</span>
                          </td>
                          <td>
                            <span>{adsAnalytics?.budgetType}</span>
                          </td>
                          <td>
                            <span>{adsAnalytics?.status ? 'Active' : 'Not Active'}</span>
                          </td>
                          <td>
                            <span>{adsAnalytics?.campaignType}</span>
                          </td>
                          <td>
                            <span>{adsAnalytics?.campaignSource}</span>
                          </td>
                          <td>
                            <span>{adsAnalytics?.devicePlatform}</span>
                          </td>
                          <td>
                            <span>{adsAnalytics?.impression}</span>
                          </td>
                          <td>
                            <span>{adsAnalytics?.clicks}</span>
                          </td>
                          <td>
                            <span>{CTR + ' %'}</span>
                          </td>
                          <td>
                            <span>{'₹ ' + CPC}</span>
                          </td>
                          <td>
                            <span>{adsAnalytics?.installs}</span>
                          </td>
                          <td>
                            <span>{'₹ ' + CPI}</span>
                          </td>
                          <td>
                            <span>{ROAS}x</span>
                          </td>
                          <td>
                            <span>{'₹ ' + adsAnalytics?.roas}</span>
                          </td>
                          <td>
                            <span>{DateWithTimeFormatter(adsAnalytics?.createdAt)}</span>
                          </td>
                          <td>
                            <td>
                              <Dropdown style={{width: '50px'}}>
                                <Dropdown.Toggle
                                  as={CustomToggle}
                                  id='dropdown-custom-components'
                                ></Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    data-bs-toggle='modal'
                                    data-bs-target='#update_campaign'
                                    onClick={() => {
                                      setSelectedAdsCampaign(adsAnalytics)
                                    }}
                                  >
                                    Update
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setSelectedAdsCampaign(adsAnalytics)
                                      setIsViewMode(true)
                                    }}
                                  >
                                    View
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
            <div className='card-footer'>
              {adsAnalyticsList.length !== 0 && (
                <CustomPagination
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalPage={totalPage}
                  cb={getPagination}
                />
              )}
            </div>
          </KTCardBody>
          <div className='modal fade' tabIndex={-1} id='add_campaign'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Add Campaign</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() =>
                      setNewCampaign({
                        campaignName: '',
                        platform: '',
                        budgetType: '',
                        campaignType: '',
                        devicePlatform: '',
                        campaignSource: '',
                      })
                    }
                  >
                    <i className='fa-solid fa-xmark'></i>
                  </div>
                </div>
                <div className='modal-body'>
                  <label className='form-check-label mt-3'>Campaign Name</label>
                  <input
                    placeholder='Enter Campaign Name'
                    type='text'
                    name='campaignName'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    autoComplete='off'
                    value={newCampaign.campaignName}
                    onChange={(e) =>
                      setNewCampaign({
                        ...newCampaign,
                        campaignName: e.target.value,
                      })
                    }
                  />
                  &nbsp;
                  <label className='form-check-label mt-3'>Select Platform</label>
                  <select
                    className='form-select form-select-solid '
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='gender'
                    data-hide-search='true'
                    name='platform'
                    value={newCampaign.platform}
                    onChange={(e) =>
                      setNewCampaign({
                        ...newCampaign,
                        platform: e.target.value,
                      })
                    }
                  >
                    <option value={''}>Select Platform</option>
                    <option value={'Facebook Ads'}>Facebook Ads</option>
                    <option value={'Google Ads'}>Google Ads</option>
                    <option value={'Instagram Pages'}>Instagram Pages</option>
                    <option value={'Instagram Influencer'}>Instagram Influencer</option>
                    <option value={'Youtube Influencer'}>Youtube Influencer</option>
                    <option value={'Whatsapp'}>Whatsapp</option>
                    <option value={'Telegram'}>Telegram</option>
                    <option value={'Email'}>Email</option>
                    <option value={'News Paper'}>News Paper</option>
                    <option value={'Snap Chat'}>Snap Chat</option>
                    <option value={'Tik tok'}>Tik tok</option>
                    <option value={'Other'}>Other</option>
                  </select>
                  &nbsp;
                  <label className='form-check-label mt-3'>Budget Type</label>
                  <select
                    className='form-select form-select-solid '
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='gender'
                    data-hide-search='true'
                    name='budgetType'
                    value={newCampaign.budgetType}
                    onChange={(e) =>
                      setNewCampaign({
                        ...newCampaign,
                        budgetType: e.target.value,
                      })
                    }
                  >
                    <option value={''}>Select budget Type</option>
                    <option value={'daily'}>Daily</option>
                    <option value={'weekly'}>Weekly</option>
                    <option value={'monthly'}>Monthly</option>
                    <option value={'yearly'}>Yearly</option>
                    <option value={'one time'}>One Time</option>
                  </select>
                  &nbsp;
                  <label className='form-check-label'>Campaign Type</label>
                  <select
                    className='form-select form-select-solid '
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='gender'
                    data-hide-search='true'
                    name='campaignType'
                    value={newCampaign.campaignType}
                    onChange={(e) =>
                      setNewCampaign({
                        ...newCampaign,
                        campaignType: e.target.value,
                      })
                    }
                  >
                    {' '}
                    <option value={''}>Select Display Type</option>
                    <option value={'display'}>Display</option>
                    <option value={'appinstall'}>App Install</option>
                    <option value={'video'}>Video</option>
                    <option value={'post'}>Post</option>
                    <option value={'story'}>Story</option>
                    <option value={'reel'}>Reel</option>
                    <option value={'podcast'}>Pod Cast</option>
                    <option value={'Awareness'}>Awareness</option>
                    <option value={'Traffic'}>Traffic</option>
                    <option value={'Engagement'}>Engagement</option>
                    <option value={'Leads'}>Leads</option>
                    <option value={'App promotion'}>App promotion</option>
                    <option value={'Sales'}>Sales</option>
                  </select>
                  &nbsp;
                  <label className='form-check-label mt-3'>Select Device Platform</label>
                  <select
                    className='form-select form-select-solid '
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='gender'
                    data-hide-search='true'
                    name='devicePlatform'
                    value={newCampaign.devicePlatform}
                    onChange={(e) =>
                      setNewCampaign({
                        ...newCampaign,
                        devicePlatform: e.target.value,
                      })
                    }
                  >
                    <option value={''}>Select Device Platform</option>
                    <option value={'ios'}>Ios</option>
                    <option value={'android'}>Android</option>
                  </select>
                  &nbsp;
                  <label className='form-check-label mt-3'>Campaign Source</label>
                  <input
                    placeholder='Enter Campaign Source'
                    type='text'
                    name='campaignSource'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    autoComplete='off'
                    value={newCampaign.campaignSource}
                    onChange={(e) =>
                      setNewCampaign({
                        ...newCampaign,
                        campaignSource: e.target.value,
                      })
                    }
                  />
                  &nbsp;
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-light'
                    data-bs-dismiss='modal'
                    onClick={() =>
                      setNewCampaign({
                        campaignName: '',
                        platform: '',
                        budgetType: '',
                        campaignType: '',
                        devicePlatform: '',
                        campaignSource: '',
                      })
                    }
                  >
                    Cancel
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary'
                    data-bs-dismiss='modal'
                    onClick={handleAddCampaign}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='modal fade' tabIndex={-1} id='update_campaign'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Update Campaign</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() =>
                      setSelectedAdsCampaign({
                        campaignName: '',
                        platform: '',
                        budgetType: '',
                        campaignType: '',
                        devicePlatform: '',
                        campaignSource: '',
                        status: true,
                      })
                    }
                  >
                    <i className='fa-solid fa-xmark'></i>
                  </div>
                </div>
                <div className='modal-body'>
                  <label className='form-check-label mt-3'>Campaign Name</label>
                  <input
                    placeholder='Enter Campaign Name'
                    type='text'
                    name='campaignName'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    autoComplete='off'
                    value={selectedAdsCampaign.campaignName}
                    onChange={(e) =>
                      setSelectedAdsCampaign({
                        ...selectedAdsCampaign,
                        campaignName: e.target.value,
                      })
                    }
                  />
                  &nbsp;
                  <label className='form-check-label mt-3'>Select Platform</label>
                  <select
                    className='form-select form-select-solid '
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='gender'
                    data-hide-search='true'
                    name='platform'
                    value={selectedAdsCampaign.platform}
                    onChange={(e) =>
                      setSelectedAdsCampaign({
                        ...selectedAdsCampaign,
                        platform: e.target.value,
                      })
                    }
                  >
                    <option value={'Facebook Ads'}>Facebook Ads</option>
                    <option value={'Google Ads'}>Google Ads</option>
                    <option value={'Instagram Pages'}>Instagram Pages</option>
                    <option value={'Instagram Influencer'}>Instagram Influencer</option>
                    <option value={'Youtube Influencer'}>Youtube Influencer</option>
                    <option value={'Whatsapp'}>Whatsapp</option>
                    <option value={'Telegram'}>Telegram</option>
                    <option value={'Email'}>Email</option>
                    <option value={'News Paper'}>News Paper</option>
                    <option value={'Snap Chat'}>Snap Chat</option>
                    <option value={'Tik tok'}>Tik tok</option>
                    <option value={'Other'}>Other</option>
                  </select>
                  &nbsp;
                  <label className='form-check-label mt-3'>Budget Type</label>
                  <select
                    className='form-select form-select-solid '
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='gender'
                    data-hide-search='true'
                    name='budgetType'
                    value={selectedAdsCampaign.budgetType}
                    onChange={(e) =>
                      setSelectedAdsCampaign({
                        ...selectedAdsCampaign,
                        budgetType: e.target.value,
                      })
                    }
                  >
                    <option value={'daily'}>Daily</option>
                    <option value={'weekly'}>Weekly</option>
                    <option value={'monthly'}>Monthly</option>
                    <option value={'yearly'}>Yearly</option>
                    <option value={'one time'}>One Time</option>
                  </select>
                  &nbsp;
                  <label className='form-check-label mt-2'>Campaign Type</label>
                  <select
                    className='form-select form-select-solid '
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='gender'
                    data-hide-search='true'
                    name='campaignType'
                    value={selectedAdsCampaign.campaignType}
                    onChange={(e) =>
                      setSelectedAdsCampaign({
                        ...selectedAdsCampaign,
                        campaignType: e.target.value,
                      })
                    }
                  >
                    <option value={'display'}>Display</option>
                    <option value={'appinstall'}>App Install</option>
                    <option value={'video'}>Video</option>
                    <option value={'post'}>Post</option>
                    <option value={'story'}>Story</option>
                    <option value={'reel'}>Reel</option>
                    <option value={'podcast'}>Pod Cast</option>
                    <option value={'Awareness'}>Awareness</option>
                    <option value={'Traffic'}>Traffic</option>
                    <option value={'Engagement'}>Engagement</option>
                    <option value={'Leads'}>Leads</option>
                    <option value={'App promotion'}>App promotion</option>
                    <option value={'Sales'}>Sales</option>
                  </select>
                  &nbsp;
                  <label className='form-check-label mt-3'>Status</label>
                  <select
                    className='form-select form-select-solid '
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='gender'
                    data-hide-search='true'
                    name='status'
                    defaultValue={selectedAdsCampaign?.status}
                    onChange={(e) =>
                      setSelectedAdsCampaign({
                        ...selectedAdsCampaign,
                        status: e.target.value == '1' ? true : false,
                      })
                    }
                  >
                    <option value={'1'}>Active</option>
                    <option value={'0'}>Not Active</option>
                  </select>
                  &nbsp;
                  <label className='form-check-label mt-3'>Select Device Platform</label>
                  <select
                    className='form-select form-select-solid '
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='gender'
                    data-hide-search='true'
                    name='devicePlatform'
                    value={selectedAdsCampaign.devicePlatform}
                    onChange={(e) =>
                      setSelectedAdsCampaign({
                        ...selectedAdsCampaign,
                        devicePlatform: e.target.value,
                      })
                    }
                  >
                    <option value={''}>Select Device Platform</option>
                    <option value={'ios'}>Ios</option>
                    <option value={'android'}>Android</option>
                  </select>
                  &nbsp;
                  <label className='form-check-label mt-3'>Campaign Source</label>
                  <input
                    placeholder='Enter Campaign Source'
                    type='text'
                    name='campaignSource'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    autoComplete='off'
                    value={selectedAdsCampaign.campaignSource}
                    onChange={(e) =>
                      setSelectedAdsCampaign({
                        ...selectedAdsCampaign,
                        campaignSource: e.target.value,
                      })
                    }
                  />
                  &nbsp;
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-light'
                    data-bs-dismiss='modal'
                    onClick={() =>
                      setSelectedAdsCampaign({
                        campaignName: '',
                        platform: '',
                        budgetType: '',
                        campaignType: '',
                        devicePlatform: '',
                        campaignSource: '',
                        status: true,
                      })
                    }
                  >
                    Cancel
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary'
                    data-bs-dismiss='modal'
                    onClick={handleUpdateCampaign}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default AdsAnalytics
