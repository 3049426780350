import React, {useEffect, useState} from 'react'
import {
  getConfigurationByName,
  updateConfigurationByConfigID,
} from '../../../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'

const RewardPlugin = () => {
  const [configID, setConfigId] = useState(0)
  const [referralConfigID, setReferralConfigId] = useState(0)
  const [rewardConfig, setRewardConfig] = useState<any>({
    initialCredit: 200,
    isEnableRewards: true,
    profileCompleteCredit: 100,
    isMultiDeviceRewardActive: true,
  })
  const [referralRewardConfig, setReferralRewardConfig] = useState<any>({
    isEnable: true,
    credit: 300,
  })

  useEffect(() => {
    getConfiguration()
  }, [])

  const handleChange = (event: any) => {
    let name = event.target.name
    let value = event.target.checked

    if (name !== 'isEnableRewards' && name !== 'isMultiDeviceRewardActive') {
      setRewardConfig({...rewardConfig, [name]: event.target.value})
    } else {
      setRewardConfig({...rewardConfig, [name]: value})
      updateConfiguration({...rewardConfig, [name]: value})
    }
  }

  const handleReferralChange = (event: any) => {
    let name = event.target.name
    let value = event.target.checked

    if (name !== 'isEnable') {
      setReferralRewardConfig({...referralRewardConfig, [name]: event.target.value})
    } else {
      setReferralRewardConfig({...referralRewardConfig, [name]: value})
      updateReferralConfiguration({...referralRewardConfig, [name]: value})
    }
  }

  const getConfiguration = async () => {
    let result = await getConfigurationByName('rewards')
    let result1 = await getConfigurationByName('referral-reward')
    if (result.success) {
      let parsedData = JSON.parse(result.data.values)
      setConfigId(result.data.id)
      setRewardConfig({
        initialCredit: parsedData?.initialCredit,
        isEnableRewards: parsedData?.isEnableRewards,
        profileCompleteCredit: parsedData?.profileCompleteCredit,
        isMultiDeviceRewardActive: parsedData?.isMultiDeviceRewardActive,
      })
    }
    if (result1.success) {
      let parsedData = JSON.parse(result1.data.values)
      setReferralConfigId(result1.data.id)
      setReferralRewardConfig({
        isEnable: parsedData?.isEnable,
        credit: parsedData?.credit,
      })
    }
  }

  const updateConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(configID, config, null)
    if (result.success) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  const updateReferralConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(referralConfigID, config, null)
    if (result.success) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  const onBlurUpdate = () => {
    updateConfiguration(rewardConfig)
  }

  const onBlurReferralUpdate = () => {
    updateReferralConfiguration(referralRewardConfig)
  }

  return (
    <div className='card'>
      <div className='card-title p-8'>
        <h2>Reward settings</h2>
      </div>
      <div className='card-body'>
        <div className='row no-gutters'>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable Rewards plugin</strong>
            </p>
            <p className='text-muted'>Enable or disable Rewards plugin</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isEnableRewards'
                checked={rewardConfig.isEnableRewards}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable Multi Account Reward</strong>
            </p>
            <p className='text-muted'>Enable or disable Multi Account Reward</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isMultiDeviceRewardActive'
                checked={rewardConfig.isMultiDeviceRewardActive}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>New accounts free credits</strong>
            </p>
            <p className='text-muted'>Give some free credits to new account users</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <input
                type='number'
                className='form-control'
                name='initialCredit'
                value={rewardConfig.initialCredit}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Give Profile Complete Coins</strong>
            </p>
            <p className='text-muted'>Give Profile Complete Coins to new accounts</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <input
                type='number'
                className='form-control'
                name='profileCompleteCredit'
                value={rewardConfig.profileCompleteCredit}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurUpdate}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='card-body'>
        <div className='row no-gutters'>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable Referral Rewards plugin</strong>
            </p>
            <p className='text-muted'>Enable or disable Referral Rewards plugin</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isEnable'
                checked={referralRewardConfig.isEnable}
                onChange={(event) => handleReferralChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Give Referral Reward Coins</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <input
                type='number'
                className='form-control'
                name='credit'
                value={referralRewardConfig.credit}
                onChange={(event) => handleReferralChange(event)}
                onBlur={onBlurReferralUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RewardPlugin
