import React, {useEffect, useState} from 'react'
import {
  getConfigurationByName,
  updateConfigurationByConfigID,
} from '../../../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'
import Swal from 'sweetalert2'

const GeneralPlugin = () => {
  const [authConfigID, setAuthConfigId] = useState(0)
  const [generalConfigID, setGeneralConfigId] = useState(0)
  const [taxConfigID, setTaxConfigId] = useState(0)

  const [authConfig, setAuthConfig] = useState<any>({
    isEnabledForceEmailVerifications: true,
    isEnabledForceMobileVerifications: true,
    isMaintenance: false,
    isEnabledGoogleLogin: false,
    isEnabledAppleLogin: false,
    isEnabledGuestLogin: false,
    isEnabledEmailLogin: false,
    tokenVersion: '',
  })

  const [generalConfig, setGeneralConfig] = useState<any>({
    isWebSocketEnabled: false,
    isInAppPurchaseEnabled: true,
    isProfileImageRequired: true,
  })

  const [taxConfig, setTaxConfig] = useState<any>({
    isIncludeTax: false,
  })

  useEffect(() => {
    getConfiguration()
  }, [])

  const handleAuthChange = (event: any) => {
    let name = event.target.name
    let value = event.target.checked

    if (name === 'tokenVersion') {
      setAuthConfig({...authConfig, [name]: event.target.value})
    } else {
      setAuthConfig({...authConfig, [name]: value})
      updateAuthConfiguration({...authConfig, [name]: value})
    }
  }

  const handleGeneralChange = (event: any) => {
    let name = event.target.name
    let value = event.target.checked

    setGeneralConfig({...generalConfig, [name]: value})
    updateGeneralConfiguration({...generalConfig, [name]: value})
  }

  const handleTaxChange = (event: any) => {
    let value = event.target.checked

    Swal.fire({
      title: 'Are you sure About Change Include Tax?',
      //text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setTaxConfig({isIncludeTax: value})
        updateTaxConfiguration({isIncludeTax: value})
      }
    })
  }

  const onBlurAuthUpdate = () => {
    updateAuthConfiguration(authConfig)
  }

  const getConfiguration = async () => {
    let result1 = await getConfigurationByName('auth')
    let result2 = await getConfigurationByName('general_setting')
    let result3 = await getConfigurationByName('tax')

    if (result1.success) {
      let parsedData = JSON.parse(result1.data.values)
      setAuthConfigId(result1.data.id)
      setAuthConfig({
        isEnabledForceEmailVerifications: parsedData?.isEnabledForceEmailVerifications,
        isEnabledForceMobileVerifications: parsedData?.isEnabledForceMobileVerifications,
        isMaintenance: parsedData?.isMaintenance,
        isEnabledGoogleLogin: parsedData?.isEnabledGoogleLogin,
        isEnabledAppleLogin: parsedData?.isEnabledAppleLogin,
        isEnabledGuestLogin: parsedData?.isEnabledGuestLogin,
        isEnabledEmailLogin: parsedData?.isEnabledEmailLogin,
        tokenVersion: parsedData?.tokenVersion,
      })
    }
    if (result2.success) {
      let parsedData = JSON.parse(result2.data.values)
      setGeneralConfigId(result2.data.id)
      setGeneralConfig({
        isWebSocketEnabled: parsedData?.isWebSocketEnabled,
        isInAppPurchaseEnabled: parsedData?.isInAppPurchaseEnabled,
        isProfileImageRequired: parsedData?.isProfileImageRequired,
      })
    }

    if (result3.success) {
      let parsedData = JSON.parse(result3.data.values)
      setTaxConfigId(result3.data.id)
      setTaxConfig({
        isIncludeTax: parsedData?.isIncludeTax,
      })
    }
  }

  const updateTaxConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(taxConfigID, config, null)
    if (result.success) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  const updateAuthConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(authConfigID, config, null)
    if (result.success) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  const updateGeneralConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(generalConfigID, config, null)
    if (result.success) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  return (
    <div className='card'>
      <div className='card-title p-8'>
        <h2>General settings</h2>
      </div>
      <div className='card-body'>
        <div className='row no-gutters'>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Force users to verify their email</strong>
            </p>
            <p className='text-muted'>
              This feature requires a working configuration of the SMTP plugin, before enable this
              make sure your SMTP service is working correctly
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isEnabledForceEmailVerifications'
                checked={authConfig.isEnabledForceEmailVerifications}
                onChange={(event) => handleAuthChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Force users to verify their Mobile No</strong>
            </p>
            <p className='text-muted'>In User Onboarding Enable Or Disable Verify a Mobile No</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isEnabledForceMobileVerifications'
                checked={authConfig.isEnabledForceMobileVerifications}
                onChange={(event) => handleAuthChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Force Set to Maintenance</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isMaintenance'
                checked={authConfig.isMaintenance}
                onChange={(event) => handleAuthChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable Google Login</strong>
            </p>
            <p className='text-muted'>Enable Or Disable Google Login Button</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isEnabledGoogleLogin'
                checked={authConfig.isEnabledGoogleLogin}
                onChange={(event) => handleAuthChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable Apple Login</strong>
            </p>
            <p className='text-muted'>Enable Or Disable Apple Login Button</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isEnabledAppleLogin'
                checked={authConfig.isEnabledAppleLogin}
                onChange={(event) => handleAuthChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable Guest Checkout</strong>
            </p>
            <p className='text-muted'>Enable Or Disable Guest Checkout</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isEnabledGuestLogin'
                checked={authConfig.isEnabledGuestLogin}
                onChange={(event) => handleAuthChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable Email Login</strong>
            </p>
            <p className='text-muted'>Enable Or Disable Email Login</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isEnabledEmailLogin'
                checked={authConfig.isEnabledEmailLogin}
                onChange={(event) => handleAuthChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>app Purchase Enabled</strong>
            </p>
            <p className='text-muted'>Enable Or Disable In App Purchase</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isInAppPurchaseEnabled'
                checked={generalConfig.isInAppPurchaseEnabled}
                onChange={(event) => handleGeneralChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Is Profile Photo Is Required</strong>
            </p>
            <p className='text-muted'>In Onboarding Enable Or Disable Profile Photo Is Required</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isProfileImageRequired'
                checked={generalConfig.isProfileImageRequired}
                onChange={(event) => handleGeneralChange(event)}
              />
            </div>
          </div>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>
                Access Token Version Code (not implemented)
              </strong>
            </p>
            <p className='text-muted'>
              If You Change This Version Code All login User Will Be ForceFully Logout
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                type='number'
                className='form-control'
                name='tokenVersion'
                value={authConfig.tokenVersion}
                onChange={(event) => handleAuthChange(event)}
                onBlur={onBlurAuthUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Is Include Tax</strong>
            </p>
            <p className='text-muted'>Enable Or Disable Include Tax</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isIncludeTax'
                checked={taxConfig.isIncludeTax}
                onChange={(event) => handleTaxChange(event)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralPlugin
