import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Plugins from '../modules/apps/plugins/Plugins'
import {PageTitle} from '../../_metronic/layout/core'
import {ModeratorDashboard} from '../pages/dashboard/ModeratorDashboard'
import AdsAnalytics from '../modules/apps/analytics-management/AdsAnalytics'

const PrivateRoutes = () => {
  const loginUser: any = JSON.parse(localStorage.getItem('kt-auth-react-v') || '')

  /*================================ Lazy Loading start=========================================*/

  const ProfilePage = lazy(() => import('../modules/apps/profile/ProfilePage'))
  const AdminUserPage = lazy(() => import('../modules/apps/admin-user-profile/AdminProfilePage'))
  const CreateNewAnonymousUser = lazy(
    () =>
      import(
        '../modules/apps/anonymous-user-management/users-list/components/create-new-user/CreateNewAnonymousUser'
      )
  )
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const AnonymousUsersPage = lazy(
    () => import('../modules/apps/anonymous-user-management/AnonymousUsersPage')
  )
  const AdminUsersPage = lazy(() => import('../modules/apps/admin-user-management/AdminUsersPage'))
  const WithdrawalRequests = lazy(
    () => import('../modules/apps/withdrawal-requests/WithdrawalRequests')
  )
  const ChatModeratorsPage = lazy(() => import('../modules/apps/chat-moderators/ChatModerators'))
  const UserVerification = lazy(() => import('../modules/apps/user-verification/UserVerification'))
  const AnonymousChat = lazy(() => import('../modules/apps/anonymous-user-chat/AnonymousChat'))

  const UserOrders = lazy(() => import('../modules/apps/user-orders/UserOrders'))

  const DailyAnalyticsManagement = lazy(
    () => import('../modules/apps/analytics-management/DailyAnalyticsManagement')
  )
  const PackageAnalyticsManagement = lazy(
    () => import('../modules/apps/analytics-management/PackageAnalyticsManagement')
  )

  const SupportSystem = lazy(() => import('../modules/apps/support-system/SupportSystem'))
  const SupportChatDrawer = lazy(() => import('../modules/apps/support-system/SupportChatDrawer'))
  const Graphs = lazy(() => import('../modules/apps/analytics-management/GraphsAnalytics'))
  const ReportedUsers = lazy(() => import('../modules/apps/blocked-user-management/BlockedUser'))
  const InvoiceManagement = lazy(
    () => import('../modules/apps/invoice-management/InvoiceManagement')
  )
  const GuestUserManagement = lazy(
    () => import('../modules/apps/guest-user-management/guestUserManagement')
  )

  /*================================ Lazy Loading end=========================================*/

  if (loginUser.roleName == 'Chat Moderator') {
    return (
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path='auth/*' element={<Navigate to='/mdashboard' />} />
          {/* Pages */}

          <Route path='mdashboard' element={<ModeratorDashboard />} />

          <Route
            path='apps/anonymous-user-management/*'
            element={
              <SuspensedView>
                <AnonymousUsersPage />
              </SuspensedView>
            }
          />

          {/* we have created 2 routes for profile page bcs we need a differentiate between normal user and anonymous user */}
          <Route
            path='apps/users-profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/anonymous-user/users-profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='anonymous-user-chat/*'
            element={
              <SuspensedView>
                <AnonymousChat />
              </SuspensedView>
            }
          />

          <Route
            path='apps/chat-moderator-management/*'
            element={
              <SuspensedView>
                <ChatModeratorsPage />
              </SuspensedView>
            }
          />

          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    )
  } else if (loginUser.roleName == 'Admin Chat Moderator') {
    return (
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path='auth/*' element={<Navigate to='/mdashboard' />} />
          {/* Pages */}

          <Route path='mdashboard' element={<ModeratorDashboard />} />

          <Route
            path='apps/anonymous-user-management/*'
            element={
              <SuspensedView>
                <AnonymousUsersPage />
              </SuspensedView>
            }
          />

          {/* we have created 2 routes for profile page bcs we need a differentiate between normal user and anonymous user */}
          <Route
            path='apps/users-profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/anonymous-user/users-profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='anonymous-user-chat/*'
            element={
              <SuspensedView>
                <AnonymousChat />
              </SuspensedView>
            }
          />

          <Route
            path='apps/chat-moderator-management/*'
            element={
              <SuspensedView>
                <ChatModeratorsPage />
              </SuspensedView>
            }
          />

          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    )
  } else {
    return (
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}

          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='mdashboard' element={<ModeratorDashboard />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          {/* Lazy Modules */}
          <Route
            path='crafted/widgets/*'
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/guest-user-management'
            element={
              <SuspensedView>
                <GuestUserManagement />
              </SuspensedView>
            }
          />

          <Route
            path='apps/anonymous-user-management/*'
            element={
              <SuspensedView>
                <AnonymousUsersPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/admin-user-management/*'
            element={
              <SuspensedView>
                <AdminUsersPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/chat-moderator-management/*'
            element={
              <SuspensedView>
                <ChatModeratorsPage />
              </SuspensedView>
            }
          />
          <Route
            path='app/register-anonymous-user/*'
            element={
              <SuspensedView>
                <CreateNewAnonymousUser />
              </SuspensedView>
            }
          />

          {/* we have created 2 routes for profile page bcs we need a differentiate between normal user and anonymous user */}
          <Route
            path='apps/users-profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />

          <Route
            path='apps/anonymous-user/users-profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/admin-user/users-profile/*'
            element={
              <SuspensedView>
                <AdminUserPage />
              </SuspensedView>
            }
          />

          <Route
            path='plugins/*'
            element={
              <SuspensedView>
                <Plugins />
              </SuspensedView>
            }
          />
          <Route path='user-media/*' element={<SuspensedView></SuspensedView>} />
          <Route
            path='user-verification/*'
            element={
              <SuspensedView>
                <UserVerification />
              </SuspensedView>
            }
          />
          <Route
            path='anonymous-user-chat/*'
            element={
              <SuspensedView>
                <AnonymousChat />
              </SuspensedView>
            }
          />
          <Route
            path='user-order'
            element={
              <SuspensedView>
                <PageTitle>User Orders</PageTitle>
                <UserOrders />
              </SuspensedView>
            }
          />

          <Route
            path='withdrawal-requests'
            element={
              <SuspensedView>
                <PageTitle>WithDrawal Requests</PageTitle>
                <WithdrawalRequests />
              </SuspensedView>
            }
          />
          <Route
            path='support-system'
            element={
              <SuspensedView>
                <PageTitle>Support System</PageTitle>
                <SupportSystem />
              </SuspensedView>
            }
          />
          <Route
            path='support_chat/:supportId/user/:userId'
            element={
              <SuspensedView>
                <PageTitle>Support Chat</PageTitle>
                <SupportChatDrawer />
              </SuspensedView>
            }
          />
          <Route
            path='analytics/daily-analytics'
            element={
              <SuspensedView>
                <PageTitle>Daily Analytics Management</PageTitle>
                <DailyAnalyticsManagement />
              </SuspensedView>
            }
          />
          <Route
            path='analytics/premium-analytics'
            element={
              <SuspensedView>
                <PageTitle>Packages Page Analytics</PageTitle>
                <PackageAnalyticsManagement />
              </SuspensedView>
            }
          />
          <Route
            path='analytics/graphs-analytics'
            element={
              <SuspensedView>
                {/* <PageTitle>Graphs Analytics Management</PageTitle> */}
                <Graphs />
              </SuspensedView>
            }
          />
          <Route
            path='analytics/ads-analytics'
            element={
              <SuspensedView>
                <PageTitle>Ads Analytics Management</PageTitle>
                <AdsAnalytics />
              </SuspensedView>
            }
          />
          <Route
            path='blocked-user-management'
            element={
              <SuspensedView>
                <PageTitle>Blocked User Management</PageTitle>
                <ReportedUsers />
              </SuspensedView>
            }
          />
          <Route
            path='invoice-management'
            element={
              <SuspensedView>
                <PageTitle>Invoice Management</PageTitle>
                <InvoiceManagement />
              </SuspensedView>
            }
          />

          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    )
  }
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
