import React, {useEffect, useState} from 'react'
import {getChatModeratorsWiseAnalytics} from '../../../../API/api-endpoint'
import BarCharts from '../../../../_metronic/partials/componants/Charts/BarCharts'
import DatePicker from '../../../../_metronic/partials/componants/DatePicker'

const ModeratorBarCharts = (props) => {
  let {
    creditPlanFilter,
    creditPlanFilterCount,
    handleModelChange,
    selectedModeratorId,
    updateModeratorChart,
  } = props

  const loginUser: any = JSON.parse(localStorage.getItem('kt-auth-react-v') || '')

  const [moderatorAnalyticsList, setModeratorAnalyticsList] = useState<any>(undefined)
  const [chartFilter, setChartFilter] = useState<any>({
    duration: 'custom',
    startDate: new Date().setDate(new Date().getDate() - 6),
    endDate: new Date(),
    view: 'day',
    day: 7,
  })

  useEffect(() => {
    getModeratorAnalyticsData()
  }, [chartFilter, updateModeratorChart, selectedModeratorId])

  const getModeratorAnalyticsData = async () => {
    let result = await getChatModeratorsWiseAnalytics(
      chartFilter,
      'DD MMM',
      selectedModeratorId,
      creditPlanFilter
    )

    if (result.success) {
      setModeratorAnalyticsList(result.data)
    }
  }

  return (
    <div className='card mb-5'>
      <div className='d-flex justify-content-between mt-2'>
        <div className='d-flex justify-content-between'>
          <div className='card p-2 ms-2 mb-2'>
            <h3>Users</h3>
            <p>{moderatorAnalyticsList?.userCount?.totalSignUps || 0}</p>
          </div>

          <div className='card p-2 ms-2 mb-2'>
            <h3>New Coins</h3>
            <p>{moderatorAnalyticsList?.creditOrder?.totalNewOrders || 0}</p>
          </div>
          <div className='card p-2 ms-2 mb-2'>
            <h3>Re. Coins</h3>
            <p>{moderatorAnalyticsList?.creditOrder?.totalRecurringOrders || 0}</p>
          </div>
          {/* <div className='card p-2 ms-2 mb-2'>
            <h3>Total Coins</h3>
            <p>
              {parseInt(moderatorAnalyticsList?.creditOrder?.totalOrders) +
                parseInt(moderatorAnalyticsList?.premiumOrder?.totalOrders) / 2 || 0}
            </p>
          </div> */}
        </div>
        <div className='d-flex'>
          {loginUser.roleName !== 'Chat Moderator' && (
            <div onClick={() => handleModelChange(true)} className='me-3 mt-3'>
              <i className='fa-solid fa-filter fa-2xl'></i>
              {creditPlanFilterCount > 0 && <span> ({creditPlanFilterCount})</span>}
            </div>
          )}
          <div className='me-2'>
            <DatePicker
              selectionRange={chartFilter}
              setSelectionRange={setChartFilter}
              isAddDayView={true}
              isToggleShow={false}
            />
          </div>
          <div>
            <select
              className='form-select form-select-solid fw-bolder select2-dropdown select2-dropdown--below'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='type'
              data-hide-search='true'
              name='view'
              value={chartFilter.view}
              onChange={(e) => setChartFilter({...chartFilter, view: e.target.value})}
            >
              {(chartFilter.day === 1 ||
                chartFilter.day === 0 ||
                chartFilter.day === 'today' ||
                chartFilter.day === 'yesterday') && <option value='hour'>Hour</option>}
              {chartFilter.day > 1 && <option value='day'>Day</option>}
              {chartFilter.day >= 7 && <option value='week'>Week</option>}
              {chartFilter.day >= 30 && <option value='month'>Month</option>}
              {chartFilter.day > 30 && <option value='year'>Year</option>}
            </select>
          </div>
          &nbsp;
        </div>
      </div>
      <div style={{width: '100%', height: '400'}}>
        {moderatorAnalyticsList !== undefined && (
          <BarCharts
            analytics={moderatorAnalyticsList?.creditOrder?.hoursArray}
            analyticsData={[
              {
                name: 'Assign User',
                data: moderatorAnalyticsList?.userCount?.userCountArray,
              },
              {
                name: 'Coins',
                data: moderatorAnalyticsList?.creditOrder?.creditCountArray,
              },
            ]}
            xAxisTitle='Date'
            yAxisTitle='Order / Users'
            ChartsTitle=''
            chartType='line'
            colorIndex={1}
            handleChange={() => {}}
            isHorizontal={false}
          />
        )}
      </div>
    </div>
  )
}

export default ModeratorBarCharts
