/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {getSideBarCount} from '../../../../../API/api-endpoint'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const loginUser: any = JSON.parse(localStorage.getItem('kt-auth-react-v') || '')
  let [count, setCount] = useState<any>({})

  useEffect(() => {
    getCount()
  }, [])

  const getCount = async () => {
    let result = await getSideBarCount()
    if (result.success) {
      setCount(result.data)
    }
  }

  if (loginUser.roleName == 'Chat Moderator') {
    return (
      <>
        <SidebarMenuItem
          to='mdashboard'
          icon='element-11'
          title={intl.formatMessage({id: 'MENU.MODERATORDASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
          to='anonymous-user-chat'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.ANONYMOUSUSERCHAT'})}
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='apps/chat-moderator-management'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.CHATMODERATORS'})}
          fontIcon='bi-layers'
        />
      </>
    )
  } else if (loginUser.roleName == 'Admin Chat Moderator') {
    return (
      <>
        <SidebarMenuItem
          to='mdashboard'
          icon='element-11'
          title={intl.formatMessage({id: 'MENU.MODERATORDASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
          to='anonymous-user-chat'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.ANONYMOUSUSERCHAT'})}
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='apps/chat-moderator-management'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.CHATMODERATORS'})}
          fontIcon='bi-layers'
        />
      </>
    )
  } else {
    return (
      <>
        <SidebarMenuItem
          to='dashboard'
          icon='element-11'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />

        <SidebarMenuItem
          to='mdashboard'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.MODERATORDASHBOARD'})}
          fontIcon='bi-app-indicator'
        />

        <SidebarMenuItemWithSub
          to='apps/user-management'
          title={`${intl.formatMessage({id: 'MENU.USERMANAGEMENT'})} `}
          icon='abstract-28'
          fontIcon='bi-person'
        >
          <SidebarMenuItem
            to='apps/user-management/users'
            icon='abstract-28'
            title={`User List`}
            fontIcon='bi-person'
          />
          <SidebarMenuItemWithSub
            to='apps/user-management/user-media'
            title={`User Media`}
            icon='abstract-28'
            fontIcon='bi-person'
          >
            <SidebarMenuItem
              to='apps/user-management/user-media/photos'
              title={`Photos`}
              hasBullet={true}
            />
            <SidebarMenuItem
              to='apps/user-management/user-media/stories'
              title={`Stories`}
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItem
            to='apps/user-management/user-live-stream'
            icon='abstract-28'
            title={intl.formatMessage({id: 'MENU.USERLIVESTREAMING'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='apps/user-management/user-video-call'
            icon='abstract-28'
            title={intl.formatMessage({id: 'MENU.USERVIDEOCALL'})}
            fontIcon='bi-layers'
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub
          to='apps/anonymous-user-management'
          title={intl.formatMessage({id: 'MENU.ANONYMOUSUSERMANAGEMENT'})}
          icon='abstract-28'
          fontIcon='bi-person'
        >
          <SidebarMenuItem
            to='apps/anonymous-user-management/users'
            icon='abstract-28'
            title={`Anonymous User List`}
            fontIcon='bi-layers'
          />
          <SidebarMenuItemWithSub
            to='apps/anonymous-user-management/user-media'
            title={`Anonymous User Media`}
            icon='abstract-28'
            fontIcon='bi-person'
          >
            <SidebarMenuItem
              to='apps/anonymous-user-management/user-media/photos'
              title={`Photos`}
              hasBullet={true}
            />
            <SidebarMenuItem
              to='apps/anonymous-user-management/user-media/stories'
              title={`Stories`}
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
        </SidebarMenuItemWithSub>

        <SidebarMenuItem
          to='apps/guest-user-management'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.GUESTUSERS'})}
          fontIcon='bi-app-indicator'
        />

        <SidebarMenuItem
          to='apps/admin-user-management'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.ADMINUSERS'})}
          fontIcon='bi-layers'
        />

        <SidebarMenuItem
          to='apps/chat-moderator-management'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.CHATMODERATORS'})}
          fontIcon='bi-layers'
        />

        <SidebarMenuItemWithSub
          to='analytics/daily-analytics'
          title={intl.formatMessage({id: 'MENU.ANALYTICS'})}
          icon='abstract-28'
          fontIcon='bi-person'
        >
          <SidebarMenuItem
            to='analytics/daily-analytics'
            icon='abstract-28'
            title={intl.formatMessage({id: 'MENU.DAILYANALYTICS'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='analytics/premium-analytics'
            icon='abstract-28'
            title={intl.formatMessage({id: 'MENU.PREMIUMANANALYTICS'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='analytics/graphs-analytics'
            icon='abstract-28'
            title={intl.formatMessage({id: 'MENU.GRAPHSANALYTICS'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='analytics/ads-analytics'
            icon='abstract-28'
            title={intl.formatMessage({id: 'MENU.ADSANALYTICS'})}
            fontIcon='bi-layers'
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItem
          to='plugins'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.PLUGINS'})}
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='user-verification'
          icon='abstract-28'
          title={`${intl.formatMessage({id: 'MENU.USERVERIFICATION'})} (${
            count?.userVerificationCount || 0
          })`}
          fontIcon='bi-layers'
        />

        <SidebarMenuItem
          to='user-order'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.USERORDER'})}
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='anonymous-user-chat'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.ANONYMOUSUSERCHAT'})}
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='withdrawal-requests'
          icon='abstract-28'
          title={`${intl.formatMessage({id: 'MENU.WITHDRAWALREQUESTS'})} (${
            count?.userWithdrawalCount || 0
          })`}
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='support-system'
          icon='abstract-28'
          title={`${intl.formatMessage({id: 'MENU.SUPPORTSYSTEM'})} (${
            count?.userSupportCount || 0
          })`}
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='blocked-user-management'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.BLOCKEDUSERS'})}
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='invoice-management'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.INVOICEMANAGEMENT'})}
          fontIcon='bi-layers'
        />
      </>
    )
  }
}

export {SidebarMenuMain}
