import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {FormControl, InputGroup, Table} from 'react-bootstrap'
import {
  CreateCreditPackageAmountPlan,
  CreatePremiumPackageAmountPlan,
  getCreditPackageAmountPlans,
  getPremiumPackageAmountPlans,
  updateCreditPackageAmountPlan,
  updatePremiumPackageAmountPlan,
} from '../../../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'

let configName = [
  {
    name: 'Chat/day',
  },
  {
    name: 'See who likes you',
  },
  {
    name: 'See who visit you',
  },
  {
    name: 'Video call',
  },
  {
    name: 'Undo profile/day',
  },
  {
    name: 'Private photo',
  },
  {
    name: 'Live stream',
  },
  {
    name: 'Upload reels',
  },
  {
    name: 'Free Credits',
  },
  {
    name: 'Spotlight (Days)',
  },
  {
    name: 'Global online',
  },
]

const SitePricingPlugin = () => {
  const [creditAmountPackages, setCreditAmountPackages] = useState<any>([])
  const [CreditPackageTotalAmount, setCreditPackageTotalAmount] = useState<any>('')
  const [CreditPackageAmount, setCreditPackageAmount] = useState<any>('')
  const [CreditPackageCredits, setCreditPackageCredits] = useState<any>('')

  const [premiumAmountPackages, setPremiumAmountPackages] = useState<any>([])
  const [PremiumPackageTotalAmount, setPremiumPackageTotalAmount] = useState<any>('')
  const [PremiumPackageAmount, setPremiumPackageAmount] = useState<any>('')
  const [PremiumPackageDays, setPremiumPackageDays] = useState<any>('')
  const [PremiumPackageName, setPremiumPackageName] = useState<any>('')

  const [CreditAmountPackagesChanges, setCreditAmountPackagesChanges] = useState<any>({
    creditAmountPackagesID: 0,
    credit: 0,
    totalAmount: 0,
    amount: 0,
  })
  const [PremiumAmountPackagesChanges, setPremiumAmountPackagesChanges] = useState<any>({
    PremiumAmountPackagesID: 0,
    days: 0,
    totalAmount: 0,
    amount: 0,
    pkgName: '',
  })

  useEffect(() => {
    getCreditAmountPackages()
    getPremiumAmountPackages()
  }, [])

  const getCreditAmountPackages = async () => {
    let result = await getCreditPackageAmountPlans()
    if (result.success) {
      setCreditAmountPackages(result.data.amounts)
    }
  }

  const createCreditAmountPackages = async () => {
    let result = await CreateCreditPackageAmountPlan(
      CreditPackageCredits,
      CreditPackageTotalAmount,
      CreditPackageAmount
    )
    if (result.success) {
      ToastUtils({type: 'success', message: 'Coins Amount Package is Created'})
      getCreditAmountPackages()
      setCreditPackageAmount('')
      setCreditPackageTotalAmount('')
      setCreditPackageCredits('')
    } else {
      ErrorToastUtils()
    }
  }

  const getPremiumAmountPackages = async () => {
    let result = await getPremiumPackageAmountPlans()
    if (result.success) {
      setPremiumAmountPackages(result.data.amounts)
    }
  }

  const createPremiumAmountPackages = async () => {
    let result = await CreatePremiumPackageAmountPlan(
      PremiumPackageDays,
      PremiumPackageTotalAmount,
      PremiumPackageAmount,
      PremiumPackageName
    )
    if (result.success) {
      ToastUtils({type: 'success', message: 'Premium Amount Package is Created'})
      getPremiumAmountPackages()
      setPremiumPackageTotalAmount('')
      setPremiumPackageAmount('')
      setPremiumPackageDays('')
      setPremiumPackageName('')
    } else {
      ErrorToastUtils()
    }
  }

  const handleCreditAmountPackagesInputChange = (event: any, index: any) => {
    const updatedCreditAmountPackages = [...creditAmountPackages]
    updatedCreditAmountPackages[index][event.target.name] = event.target.value
    setCreditAmountPackages(updatedCreditAmountPackages)
    let creditAmountPackagesID = updatedCreditAmountPackages[index].creditPackageAmountId
    let credit: any, amount: any, totalAmount: any
    if (event.target.name === 'credit') {
      credit = event.target.value
      amount = updatedCreditAmountPackages[index].amount
      totalAmount = updatedCreditAmountPackages[index].totalAmount
    } else if (event.target.name === 'totalAmount') {
      credit = updatedCreditAmountPackages[index].credit
      amount = updatedCreditAmountPackages[index].amount
      totalAmount = event.target.value
    } else {
      credit = updatedCreditAmountPackages[index].credit
      amount = event.target.value
      totalAmount = updatedCreditAmountPackages[index].totalAmount
    }

    setCreditAmountPackagesChanges({
      creditAmountPackagesID: creditAmountPackagesID,
      credit: credit,
      totalAmount: totalAmount,
      amount: amount,
    })
  }

  const onBlurUpdateCreditAmountPackages = () => {
    updateCreditAmountPackages(
      CreditAmountPackagesChanges.creditAmountPackagesID,
      CreditAmountPackagesChanges.credit,
      CreditAmountPackagesChanges.totalAmount,
      CreditAmountPackagesChanges.amount
    )
  }

  const updateCreditAmountPackages = async (
    pkgId: any,
    credit: any,
    totalAmount: any,
    amount: any
  ) => {
    let result = await updateCreditPackageAmountPlan(pkgId, credit, totalAmount, amount)
    if (result.success) {
      ToastUtils({type: 'success', message: 'Coins Amount Package is Updated'})
    } else {
      ErrorToastUtils()
    }
  }

  const handlePremiumAmountPackagesInputChange = (event: any, index: any) => {
    const updatedPremiumAmountPackages = [...premiumAmountPackages]
    updatedPremiumAmountPackages[index][event.target.name] = event.target.value
    setPremiumAmountPackages(updatedPremiumAmountPackages)
    let premiumPackageAmountId = updatedPremiumAmountPackages[index].premiumPackageAmountId
    let days: any, amount: any, pkgName: any, totalAmount: any
    if (event.target.name === 'days') {
      days = event.target.value
      amount = updatedPremiumAmountPackages[index].amount
      pkgName = updatedPremiumAmountPackages[index].premiumPackageName
      totalAmount = updatedPremiumAmountPackages[index].totalAmount
    } else if (event.target.name === 'amount') {
      days = updatedPremiumAmountPackages[index].days
      amount = event.target.value
      pkgName = updatedPremiumAmountPackages[index].premiumPackageName
      totalAmount = updatedPremiumAmountPackages[index].totalAmount
    } else if (event.target.name === 'totalAmount') {
      days = updatedPremiumAmountPackages[index].days
      amount = updatedPremiumAmountPackages[index].amount
      pkgName = updatedPremiumAmountPackages[index].premiumPackageName
      totalAmount = event.target.value
    } else {
      amount = updatedPremiumAmountPackages[index].amount
      days = updatedPremiumAmountPackages[index].days
      pkgName = event.target.value
      totalAmount = updatedPremiumAmountPackages[index].totalAmount
    }

    setPremiumAmountPackagesChanges({
      PremiumAmountPackagesID: premiumPackageAmountId,
      days: days,
      totalAmount: totalAmount,
      amount: amount,
      pkgName: pkgName,
    })
  }

  const onBlurUpdatePremiumAmountPackages = () => {
    updatePremiumAmountPackages(
      PremiumAmountPackagesChanges.PremiumAmountPackagesID,
      PremiumAmountPackagesChanges.days,
      PremiumAmountPackagesChanges.totalAmount,
      PremiumAmountPackagesChanges.amount,
      PremiumAmountPackagesChanges.pkgName
    )
  }

  const updatePremiumAmountPackages = async (
    pkgId: any,
    days: any,
    totalAmount: any,
    amount: any,
    pkgName: any
  ) => {
    let result = await updatePremiumPackageAmountPlan(pkgId, days, totalAmount, amount, pkgName)
    if (result.success) {
      ToastUtils({type: 'success', message: 'Premium Amount Package is Updated'})
    } else {
      ErrorToastUtils()
    }
  }

  return (
    <div className='row'>
      <div className='col-lg-6 '>
        <div className='card'>
          <div className='card-title p-4'>
            <div className='d-flex justify-content-between'>
              <h4 className='mt-4 '>Price in INR to buy credits</h4>
              <button
                className='btn btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#add_credit_plan'
              >
                Add Plan
              </button>
            </div>
          </div>

          <div className='card-body'>
            <Table striped bordered hover className='mt-5'>
              <thead>
                <tr>
                  <th>Package Name</th>
                  <th>Coins</th>
                  <th>Total Price(Inr)</th>
                  <th>Price(Inr)</th>
                </tr>
              </thead>
              <tbody>
                {creditAmountPackages.length !== 0 &&
                  creditAmountPackages.map((creditAmountPackage: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>Credits package {index + 1}</td>
                        <td>
                          <InputGroup>
                            <FormControl
                              placeholder='credits'
                              name='credit'
                              value={creditAmountPackage?.credit}
                              onChange={(event) =>
                                handleCreditAmountPackagesInputChange(event, index)
                              }
                              onBlur={onBlurUpdateCreditAmountPackages}
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <InputGroup>
                            <FormControl
                              placeholder='Total Amount'
                              name='totalAmount'
                              value={creditAmountPackage?.totalAmount}
                              onChange={(event) =>
                                handleCreditAmountPackagesInputChange(event, index)
                              }
                              onBlur={onBlurUpdateCreditAmountPackages}
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <InputGroup>
                            <FormControl
                              placeholder='Amount'
                              name='amount'
                              value={creditAmountPackage?.amount}
                              onChange={(event) =>
                                handleCreditAmountPackagesInputChange(event, index)
                              }
                              onBlur={onBlurUpdateCreditAmountPackages}
                            />
                          </InputGroup>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <div className='col-lg-6'>
        <div className='card'>
          <div className='card-title p-4'>
            <div className='d-flex justify-content-between'>
              <h4 className='mt-4'>Price in INR to buy Premium</h4>
              <button
                className='btn btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#add_premium_plan'
              >
                Add Plan
              </button>
            </div>
          </div>
          <div className='card-body'>
            <Table striped bordered hover className='mt-5'>
              <thead>
                <tr>
                  <th>Package Name</th>
                  <th>Days</th>
                  <th>Total Price(Inr)</th>
                  <th>Price(Inr)</th>
                </tr>
              </thead>
              <tbody>
                {premiumAmountPackages.length !== 0 &&
                  premiumAmountPackages.map((premiumAmountPackage: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <InputGroup>
                            <FormControl
                              placeholder='premiumPackageName'
                              name='premiumPackageName'
                              value={premiumAmountPackage?.premiumPackageName}
                              onChange={(event) =>
                                handlePremiumAmountPackagesInputChange(event, index)
                              }
                              onBlur={onBlurUpdatePremiumAmountPackages}
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <InputGroup>
                            <FormControl
                              placeholder='days'
                              name='days'
                              value={premiumAmountPackage?.days}
                              onChange={(event) =>
                                handlePremiumAmountPackagesInputChange(event, index)
                              }
                              onBlur={onBlurUpdatePremiumAmountPackages}
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <InputGroup>
                            <FormControl
                              placeholder='Total Amount'
                              name='totalAmount'
                              value={premiumAmountPackage?.totalAmount}
                              onChange={(event) =>
                                handlePremiumAmountPackagesInputChange(event, index)
                              }
                              onBlur={onBlurUpdatePremiumAmountPackages}
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <InputGroup>
                            <FormControl
                              placeholder='amount'
                              name='amount'
                              value={premiumAmountPackage?.amount}
                              onChange={(event) =>
                                handlePremiumAmountPackagesInputChange(event, index)
                              }
                              onBlur={onBlurUpdatePremiumAmountPackages}
                            />
                          </InputGroup>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='add_credit_plan'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Coins Plan</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='fa-solid fa-xmark'></i>
              </div>
            </div>
            <div className='modal-body'>
              <input
                placeholder='0'
                type='number'
                name='package_credit'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                value={CreditPackageCredits}
                onChange={(e) => setCreditPackageCredits(Math.abs(parseInt(e.target.value)))}
              />
              &nbsp;&nbsp;&nbsp;
              <input
                placeholder='Enter Package Total Amount'
                type='number'
                name='package_total_amount'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                value={CreditPackageTotalAmount}
                onChange={(e) => setCreditPackageTotalAmount(Math.abs(parseInt(e.target.value)))}
              />
              &nbsp;&nbsp;&nbsp;
              <input
                placeholder='Enter Package Amount'
                type='number'
                name='package_amount'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                value={CreditPackageAmount}
                onChange={(e) => setCreditPackageAmount(Math.abs(parseInt(e.target.value)))}
              />
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
                onClick={createCreditAmountPackages}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='add_premium_plan'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Premium Plan</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='fa-solid fa-xmark'></i>
              </div>
            </div>
            <div className='modal-body'>
              <input
                placeholder='Package Name'
                type='text'
                name='package_name'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                value={PremiumPackageName}
                onChange={(e) => setPremiumPackageName(e.target.value)}
              />
              &nbsp;&nbsp;&nbsp;
              <input
                placeholder='Enter Package Days'
                type='number'
                name='package_days'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                value={PremiumPackageDays}
                onChange={(e) => setPremiumPackageDays(Math.abs(parseInt(e.target.value)))}
              />
              &nbsp;&nbsp;&nbsp;
              <input
                placeholder='Enter Package Total Amount'
                type='number'
                name='package_total_amount'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                value={PremiumPackageTotalAmount}
                onChange={(e) => setPremiumPackageTotalAmount(Math.abs(parseInt(e.target.value)))}
              />
              &nbsp;&nbsp;&nbsp;
              <input
                placeholder='Enter Package Amount'
                type='number'
                name='package_amount'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                value={PremiumPackageAmount}
                onChange={(e) => setPremiumPackageAmount(Math.abs(parseInt(e.target.value)))}
              />
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
                onClick={createPremiumAmountPackages}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SitePricingPlugin
