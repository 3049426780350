import React, {useEffect, useState} from 'react'
import {
  getConfigurationByName,
  updateConfigurationByConfigID,
} from '../../../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'

const VideocallPlugin = () => {
  const [configID, setConfigId] = useState(0)
  const [videoCallConfig, setVideoCallConfig] = useState<any>({
    isVideoCallEnabled: true,
    creditPerMin: 2,
    premiumUserOnly: true,
    isCreditDeduct: true,
    isTransferCredit: true,
    transferCreditPercentage: 100,
    deductCreditGender: 1,
    verifiedUserOnlyCreate: false,
    verifiedUserOnlyAnswer: false,
  })

  useEffect(() => {
    getConfiguration()
  }, [])

  const handleChange = (event: any) => {
    let name = event.target.name
    let value = event.target.checked

    if (name === 'creditPerMin' || name === 'transferCreditPercentage') {
      setVideoCallConfig({...videoCallConfig, [name]: event.target.value})
    } else {
      if (name === 'deductCreditGender') {
        setVideoCallConfig({...videoCallConfig, [name]: event.target.value})
        updateConfiguration({...videoCallConfig, [name]: event.target.value})
      } else {
        setVideoCallConfig({...videoCallConfig, [name]: value})
        updateConfiguration({...videoCallConfig, [name]: value})
      }
    }
  }

  const getConfiguration = async () => {
    let result = await getConfigurationByName('videocall')
    if (result.success) {
      let parsedData = JSON.parse(result.data.values)
      setConfigId(result.data.id)
      setVideoCallConfig({
        isVideoCallEnabled: parsedData?.isVideoCallEnabled,
        creditPerMin: parsedData?.creditPerMin,
        premiumUserOnly: parsedData?.premiumUserOnly,
        isCreditDeduct: parsedData?.isCreditDeduct,
        isTransferCredit: parsedData?.isTransferCredit,
        transferCreditPercentage: parsedData?.transferCreditPercentage,
        deductCreditGender: parsedData?.deductCreditGender,
        verifiedUserOnlyCreate: parsedData?.verifiedUserOnlyCreate,
        verifiedUserOnlyAnswer: parsedData?.verifiedUserOnlyAnswer,
      })
    }
  }

  const onBlurUpdate = () => {
    updateConfiguration(videoCallConfig)
  }

  const updateConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(configID, config, null)
    if (result.success) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row no-gutters'>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable Video Calling</strong>
            </p>
            <p className='text-muted'>Enable or disable Video Calling for the software</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isVideoCallEnabled'
                checked={videoCallConfig.isVideoCallEnabled}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Premium User Can Only video call</strong>
            </p>
            <p className='text-muted'>
              By default, video calls are only available to all users. To allow Premium users to
              start a video call, enable this feature for them.
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='premiumUserOnly'
                checked={videoCallConfig.premiumUserOnly}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Verified User Can Only video call</strong>
            </p>
            <p className='text-muted'>
              By default, video calls are only available to all users. To allow Verified users to
              start a video call, enable this feature for them.
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='verifiedUserOnlyCreate'
                checked={videoCallConfig.verifiedUserOnlyCreate}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Verified User Can Only Answer video call</strong>
            </p>
            <p className='text-muted'>
              By default, video calls are only available to all users. To allow Verified users to
              Answer a video call, enable this feature for them.
            </p>
            <p className='text-danger'>(Not Implemented in mobile)</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='verifiedUserOnlyAnswer'
                checked={videoCallConfig.verifiedUserOnlyAnswer}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable credits per minute</strong>
            </p>
            <p className='text-muted'>
              Charge specific amount of credits per minute on the videocalling
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isCreditDeduct'
                checked={videoCallConfig.isCreditDeduct}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Charge credits per minutes</strong>
            </p>
            <p className='text-muted'>
              Videocalling requires credits per minutes for work, the credits will be charged only
              to the caller user
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>
                Amount Of Coins
              </label>
              <input
                type='number'
                className='form-control'
                name='creditPerMin'
                value={videoCallConfig.creditPerMin}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurUpdate}
                disabled={!videoCallConfig.isCreditDeduct}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Charge credits to specific gender</strong>
            </p>
            <p className='text-muted'>Charge credits per message only to the selected gender</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label htmlFor='creditsPerMessageGender'>Charge credits gender</label>
              <br />
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='gender'
                data-hide-search='true'
                defaultValue={videoCallConfig.deductCreditGender}
                name='deductCreditGender'
                value={videoCallConfig.deductCreditGender}
                onChange={(event) => handleChange(event)}
              >
                <option value={1}>Male</option>
                <option value={2}>Female</option>
              </select>
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Transfer videocall credits</strong>
            </p>
            <p className='text-muted'>
              Transfer to the called user the spent credits by the caller user
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isTransferCredit'
                checked={videoCallConfig.isTransferCredit}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Transfer Coins Percentage</strong>
            </p>
            <p className='text-muted'>
              Its Transfer Only That Percentage which You Have Entered Here
            </p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>
              Coins Percentage
              </label>
              <input
                type='number'
                className='form-control'
                name='transferCreditPercentage'
                value={videoCallConfig.transferCreditPercentage}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurUpdate}
                disabled={!videoCallConfig.isTransferCredit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideocallPlugin
