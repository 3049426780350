import React, {useEffect, useState} from 'react'
import LineCharts from '../../../../_metronic/partials/componants/Charts/LineCharts'
import {getTotalOrderAnalytics, getUserAnalytics} from '../../../../API/api-endpoint'
import clsx from 'clsx'

const StaticCharts = () => {
  const [tabValue, setTabValue] = useState<any>('user-chart')
  const [totalOrderAnalytics, setTotalOrderAnalytics] = useState<any>(undefined)
  const [userAnalytics, setUserAnalytics] = useState<any>(undefined)
  const [chartFilter, setChartFilter] = useState<any>({
    duration: 'custom',
    startDate: new Date().setDate(new Date().getDate() - 6),
    endDate: new Date().setDate(new Date().getDate() + 1),
    view: 'day',
    day: 7,
    type: 'actualOrders',
  })

  useEffect(() => {
    if (tabValue === 'user-chart') {
      getUserAnalyticData()
    } else {
      getTotalOrderAnalyticData()
    }
  }, [tabValue])

  const getUserAnalyticData = async () => {
    let result = await getUserAnalytics(chartFilter, 'DD MMM')
    if (result.success) {
      result.data.countArray.pop()
      result.data.hoursArray.pop()
      setUserAnalytics(result.data)
    }
  }

  const getTotalOrderAnalyticData = async () => {
    let result = await getTotalOrderAnalytics(
      chartFilter,
      'DD MMM',
      {
        adsOrders: false,
        adminOrders: false,
        actualOrders: true,
      },
      {googlePay: true, phonePay: true, razorPay: true, applePay: true}
    )
    if (result.success) {
      result.data.countArray.pop()
      result.data.hoursArray.pop()
      setTotalOrderAnalytics(result.data)
    }
  }

  return (
    <div className='card mb-5'>
      <div className='d-flex mt-5 mb-5 justify-content-around fs-5 fw-bolder d-flex'>
        <div
          className={clsx('nav-item nav-link text-muted me-6 cursor-pointer', {
            active: tabValue === 'user-chart',
            'text-active-primary': tabValue === 'user-chart',
          })}
          onClick={() => setTabValue('user-chart')}
        >
          New Users
        </div>
        <div
          className={clsx('nav-item nav-link text-muted me-6 cursor-pointer', {
            active: tabValue === 'revenue-order',
            'text-active-primary': tabValue === 'revenue-order',
          })}
          onClick={() => setTabValue('revenue-order')}
        >
          Sales
        </div>
      </div>
      {tabValue === 'user-chart' ? (
        <div style={{width: '100%', height: '400'}}>
          {userAnalytics !== undefined && (
            <LineCharts
              analytics={userAnalytics}
              analyticsData={[
                {
                  name: 'Users',
                  data: userAnalytics?.countArray,
                },
              ]}
              xAxisTitle='Time'
              yAxisTitle='Users'
              ChartsTitle=''
              chartType='line'
              colorIndex={0}
              isRupeeSymbolShow={false}
              TimeFormatter={'DD MMM'}
            />
          )}
        </div>
      ) : (
        <div style={{width: '100%', height: '500'}}>
          {totalOrderAnalytics !== undefined && (
            <LineCharts
              analytics={totalOrderAnalytics}
              analyticsData={[
                {
                  name: 'Total Collection',
                  data: totalOrderAnalytics?.countArray,
                },
              ]}
              xAxisTitle='Time'
              yAxisTitle='Total Orders'
              ChartsTitle=''
              chartType='line'
              colorIndex={1}
              isRupeeSymbolShow={true}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default StaticCharts
