/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import CardWidget from './Componants/CardWidget'
import {
  getChatModeratorsAnalytics,
  getCreditAndPremiumPackageAmountPlans,
  getStaticDataForModeratorDashboard,
} from '../../../API/api-endpoint'
import ModeratorBarCharts from './Componants/ModeratorBarCharts'
import ModeratorList from './Componants/ModeratorList'
import {Modal} from 'react-bootstrap'
import clsx from 'clsx'
import {getCurrentMonth, getCurrentWeek} from '../../../utils/DateUtils'

const ModeratorDashboard: FC = () => {
  const intl = useIntl()

  const loginUser: any = JSON.parse(localStorage.getItem('kt-auth-react-v') || '')

  const [dashboardData, setDashboardData] = useState<any>([])
  const [transaction, setTransaction] = useState<any>('premium-plan')
  const [show, setShow] = useState(false)

  const [creditPlanCheckAll, setCreditPlanCheckAll] = useState(false)
  const [creditPlanFilterCount, setCreditPlanFilterCount] = useState(0)
  const [creditPlanFilter, setCreditPlanFilter] = useState<any>({1: true, 2: true, 3: true})
  const [creditPackages, setCreditPackages] = useState<any>([])

  const [moderatorDetailsList, setModeratorDetailsList] = useState<any>(undefined)
  const [moderatorsFilter, setModeratorsFilter] = useState<any>({
    startDate: new Date().setDate(new Date().getDate() - 6),
    endDate: new Date(),
  })
  const [staticData, setStaticData] = useState({
    todayUser: 0,

    todayNewCredit: 0,

    todayRecurringCredit: 0,
  })
  const [selectedModeratorId, setSelectedModeratorId] = useState(
    loginUser.roleName == 'Chat Moderator' ? loginUser.adminUserId : '0'
  )
  const [updateModeratorChart, setUpdateModeratorChart] = useState(0)

  useEffect(() => {
    getModeratorData()
  }, [moderatorsFilter, updateModeratorChart])

  useEffect(() => {
    let allCreditChecked = true
    for (const inputName in creditPlanFilter) {
      if (creditPlanFilter[inputName] === false) {
        allCreditChecked = false
      }
    }
    if (allCreditChecked) {
      setCreditPlanCheckAll(true)
    } else {
      setCreditPlanCheckAll(false)
    }
  }, [creditPlanFilter])

  useEffect(() => {
    getAllPackages()
  }, [])

  useEffect(() => {
    getModeratorStaticData()
  }, [selectedModeratorId])

  const getModeratorStaticData = async () => {
    let result = await getStaticDataForModeratorDashboard(selectedModeratorId)
    if (result.success) {
      let data = result.data
      setStaticData({
        todayUser: data?.todaysAssignsUser,
        todayNewCredit: data?.creditCount,
        todayRecurringCredit: data?.creditRecurringCount,
      })
    }
  }

  const getModeratorData = async () => {
    let result = await getChatModeratorsAnalytics(
      moderatorsFilter,
      creditPlanFilter,
      selectedModeratorId
    )
    if (result.success) {
      let data = result.data
      if (loginUser.roleName == 'Admin Chat Moderator') {
        data = result.data.filter((moderator) => moderator.moderatorId != 3)
      }
      setModeratorDetailsList(data)
    }
  }

  const getAllPackages = async () => {
    let result = await getCreditAndPremiumPackageAmountPlans()
    if (result.status === 200) {
      setCreditPackages(result.data.creditsPackage)

      const creditResult = result.data.creditsPackage.reduce((acc, pkg) => {
        acc[pkg.creditPackageAmountId] = true
        return acc
      }, {})

      setCreditPlanFilter(creditResult)
      setCreditPlanFilterCount(result.data.creditsPackage.length)
      setCreditPlanCheckAll(true)
    }
  }

  const clearModelFilter = () => {
    Object.keys(creditPlanFilter).forEach((key) => {
      creditPlanFilter[key] = false
    })
    setCreditPlanFilter(creditPlanFilter)
    setCreditPlanCheckAll(false)
    setCreditPlanFilterCount(0)
    setUpdateModeratorChart(updateModeratorChart + 1)
  }

  const creditPlanCheckAllCheck = (name) => {
    setCreditPlanFilter((prevChecked) => {
      const newChecked = {...prevChecked, [name]: !prevChecked[name]}
      const newCount = Object.values(newChecked).filter(Boolean).length
      setCreditPlanFilterCount(newCount)
      return newChecked
    })
  }

  const creditSelectAll = (isChecked) => {
    Object.keys(creditPlanFilter).forEach((key) => {
      creditPlanFilter[key] = isChecked
    })
    setCreditPlanFilter(creditPlanFilter)
    setCreditPlanCheckAll(isChecked)
    const newCount = isChecked ? creditPlanFilter.length : 0
    setCreditPlanFilterCount(newCount)
  }

  const handleModelChange = (bool: boolean) => {
    setShow(bool)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {moderatorDetailsList !== undefined && loginUser.roleName != 'Chat Moderator' && (
        <div className='d-flex justify-content-end mb-2'>
          <select
            className='form-select form-select-solid fw-bolder select2-dropdown select2-dropdown--below w-25'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            data-kt-user-table-filter='type'
            data-hide-search='true'
            name='view'
            value={selectedModeratorId}
            onChange={(e) => setSelectedModeratorId(e.target.value)}
          >
            <option value={'0'}>All</option>
            {moderatorDetailsList.map((moderator) => {
              return (
                <option
                  value={moderator?.moderatorId}
                  style={{color: moderator?.moderatorStatus ? 'green' : 'red'}}
                >
                  {moderator?.moderatorName}
                </option>
              )
            })}
          </select>
        </div>
      )}

      {dashboardData !== undefined && (
        <>
          <div className='row g-3 mt-2'>
            <div className='col-12 col-sm-6 col-md-4 col-lg-4 mb-5 mb-xl-10'>
              <CardWidget
                count={staticData?.todayUser}
                title='Assign Users'
                percentage=''
                isPercentageShow={false}
                icon={
                  <i className='ki-duotone ki-abstract-36 fs-2x mb-5 ms-n1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                }
              />
            </div>

            <div className='col-12 col-sm-6 col-md-4 col-lg-4 mb-5 mb-xl-10'>
              <CardWidget
                count={`${staticData?.todayNewCredit} + ${staticData?.todayRecurringCredit} = ${
                  staticData?.todayNewCredit + staticData?.todayRecurringCredit
                }`}
                title='New + Re. = Total Coins'
                percentage={0}
                isPercentageShow={false}
                icon={<i className='ki-outline ki-chart-line-up-2 fs-2x'></i>}
              />
            </div>
          </div>

          <div className=' mb-5 mb-xl-10'>
            <ModeratorBarCharts
              creditPlanFilter={creditPlanFilter}
              creditPlanFilterCount={creditPlanFilterCount}
              handleModelChange={handleModelChange}
              selectedModeratorId={selectedModeratorId}
              updateModeratorChart={updateModeratorChart}
            />
          </div>

          {loginUser.roleName !== 'Chat Moderator' && (
            <div className=' mb-5 mb-xl-10'>
              <ModeratorList
                creditPlanFilter={creditPlanFilter}
                creditPlanFilterCount={creditPlanFilterCount}
                handleModelChange={handleModelChange}
                moderatorsFilter={moderatorsFilter}
                setModeratorsFilter={setModeratorsFilter}
                moderatorDetailsList={moderatorDetailsList}
              />
            </div>
          )}
        </>
      )}
      <Modal size='lg' show={show} onHide={() => handleModelChange(false)} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row' style={{height: '350px'}}>
            <div className='col-4'>
              <div>
                <h2
                  className={clsx(
                    'fs-6 fs-lg-4 text-gray-800 fw-bold nav-item nav-link text-muted me-6 cursor-pointer mt-5 mb-5',
                    {
                      active: transaction === 'credit-plan',
                      'text-active-primary': transaction === 'credit-plan',
                    }
                  )}
                  onClick={() => setTransaction('credit-plan')}
                >
                  <span>Coins Plan</span>
                  {creditPlanFilterCount > 0 && (
                    <span className='checkmode'> ({creditPlanFilterCount}) </span>
                  )}
                </h2>
              </div>
            </div>
            <div className='col-1'>
              <div
                className='vr'
                style={{
                  height: '395px',
                  marginTop: '-22px',
                }}
              ></div>
            </div>

            {transaction === 'credit-plan' && (
              <div className='col-7' style={{fontSize: '17px'}}>
                <div>
                  <input
                    type='checkbox'
                    onChange={(event) => creditSelectAll(event.target.checked)}
                    checked={creditPlanCheckAll}
                    className={clsx('checkbox-round', 'checkbox-input me-2 mb-2', {
                      checked: creditPlanCheckAll,
                    })}
                  />
                  Select All
                </div>
                <div>
                  {creditPackages.map((credit) => {
                    return (
                      <div
                        className={clsx('checkbox-container', {
                          checked: creditPlanFilter[credit?.creditPackageAmountId],
                        })}
                        style={{width: '200px'}}
                      >
                        <label
                          htmlFor='com'
                          className={clsx('checkbox-label', {
                            checked: creditPlanFilter[credit?.creditPackageAmountId],
                          })}
                        >
                          CreditPlan{credit?.creditPackageAmountId}
                        </label>
                        <input
                          type='checkbox'
                          className={clsx('checkbox-round', 'checkbox-input')}
                          name={creditPlanFilter[credit?.creditPackageAmountId]}
                          onChange={() => creditPlanCheckAllCheck(credit?.creditPackageAmountId)}
                          checked={creditPlanFilter[credit?.creditPackageAmountId]}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-secondary' onClick={clearModelFilter}>
            Clear All Filters
          </button>
          <div>
            <button
              className='btn btn-primary'
              onClick={() => {
                handleModelChange(false)
                setUpdateModeratorChart(updateModeratorChart + 1)
              }}
            >
              Apply
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {ModeratorDashboard}
