import moment from 'moment'
import ToastUtils from './ToastUtils'

export const sortData = (startDate: any, endDate: any) => {
  let start_date = new Date(startDate)
  let end_date = new Date(endDate)
  return start_date.getTime() - end_date.getTime()
}

export const handleAPIResponse = (status: any) => {
  if (status === 200) {
    return true
  } else if (status === 401) {
    ToastUtils({type: 'error', message: 'unAuthorized User'})
  } else if (status === 500) {
    ToastUtils({type: 'error', message: 'Internal Server Error'})
  } else if (status === 404) {
    ToastUtils({type: 'error', message: 'Not Found'})
  }
}

export const GetIDFromURL = (location: any) => {
  let str = location.pathname
  let UserId = str.substring(str.lastIndexOf('/') + 1, str.length)
  localStorage.setItem('userId', UserId)
  return UserId
}

export const validateEmail = (email: any) => {
  return email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/) // /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@ ((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

//this both for check type of media in chat
export const isImage = ['.gif', '.jpg', '.jpeg', '.png']
export const isVideo = ['.mpg', '.mp2', '.mpeg', '.mpe', '.mpv', '.mp4']

export const isNumber = (string: any) => {
  return /\d/.test(string)
}

export let chartColors = [
  ['#00C673'],
  ['#2AE4F0', '#F5DC62', '#F08686', '#1FE696', '#5991C6', '#4B7F70'], //'#EDE8E8'
  ['#00B9F5'],
  ['#1B84FF', '#17C653'],
  ['#2AE4F0', '#F5DC62', '#F08686'],
]

export const getRouteForProfile = (type: any, userId: any) => {
  const loginUser: any = JSON.parse(localStorage.getItem('kt-auth-react-v') || '')
  let normalProfileRoute, anonymousProfileRoute

  if (loginUser.roleName == 'Chat Moderator' || loginUser.roleName == 'Admin Chat Moderator') {
    normalProfileRoute = `/apps/users-profile/overview/${userId}`
    anonymousProfileRoute = `/apps/anonymous-user/users-profile/overview/${userId}`
  } else {
    normalProfileRoute = `/apps/users-profile/activity/${userId}`
    anonymousProfileRoute = `/apps/anonymous-user/users-profile/activity/${userId}`
  }

  if (type !== 'anonymous') {
    return normalProfileRoute
  } else {
    return anonymousProfileRoute
  }
}

export const checkUserJoinDate = (user: any) => {
  // Get the current date
  const now = new Date()
  now.setHours(0, 0, 0, 0)

  // Calculate the start and end of today
  const todayStart = new Date(now)
  const tomorrowStart = new Date(todayStart)
  tomorrowStart.setDate(todayStart.getDate() + 1)

  // Calculate the start and end of yesterday
  const yesterdayStart = new Date(todayStart)
  yesterdayStart.setDate(todayStart.getDate() - 1)
  const todayEnd = todayStart // The start of today is the end of yesterday

  // Parse the createdAt date
  const createdAt = new Date(user?.createdAt)

  // Check if the user joined today
  if (createdAt >= todayStart && createdAt < tomorrowStart) {
    return 'T'
  }

  // Check if the user joined yesterday
  if (createdAt >= yesterdayStart && createdAt < todayEnd) {
    return 'Y'
  }

  // Otherwise, return false
  return false
}

export const convertNumberToWords = (amount: any) => {
  const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
  const teens = [
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ]
  const tens = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
  ]

  function convertBelowHundred(num) {
    if (num < 10) {
      return ones[num]
    } else if (num < 20) {
      return teens[num - 10]
    } else {
      let ten = Math.floor(num / 10)
      let unit = num % 10
      return tens[ten] + (unit !== 0 ? ' ' + ones[unit] : '')
    }
  }

  function convertBelowThousand(num) {
    let hundred = Math.floor(num / 100)
    let belowHundred = num % 100
    let result = ''
    if (hundred !== 0) {
      result += ones[hundred] + ' Hundred'
    }
    if (belowHundred !== 0) {
      if (hundred !== 0) {
        result += ' '
      }
      result += convertBelowHundred(belowHundred)
    }
    return result
  }

  if (amount === 0) {
    return 'Zero Rupees only'
  }

  let words = ''
  if (amount >= 10000000) {
    words += convertBelowThousand(Math.floor(amount / 10000000)) + ' Crore '
    amount %= 10000000
  }
  if (amount >= 100000) {
    words += convertBelowThousand(Math.floor(amount / 100000)) + ' Lakh '
    amount %= 100000
  }
  if (amount >= 1000) {
    words += convertBelowThousand(Math.floor(amount / 1000)) + ' Thousand '
    amount %= 1000
  }
  if (amount > 0) {
    words += convertBelowThousand(amount)
  }

  return words.trim() + ' Rupees only'
}

export let API_TYPE = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
}
