import {useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../../../../_metronic/layout/core'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {
  createAdsPlan,
  getAllAdsPlan,
  getPremiumPackageAmountPlans,
  updateAdsPlan,
  deleteAdsPlan,
} from '../../../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Ads Plan Plugin',
    path: 'plugin',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AdsPlanPlugin = () => {
  const [adsPlanList, setAdsPlanList] = useState<any>([])
  const [adsPlan, setAdsPlan] = useState<any>({
    type: '',
    premiumPlanId: '',
    creditAndDays: '',
    adsType: '',
  })
  const [premiumPlan, setPremiumPlan] = useState<any>([])
  const [isEdit, setIsEdit] = useState<any>(false)

  useEffect(() => {
    getPlansList()
    allPremiumPlans()
  }, [])

  const getPlansList = async () => {
    let result = await getAllAdsPlan()
    if (result.status === 200) {
      setAdsPlanList(result.data)
    } else {
      ErrorToastUtils()
    }
  }

  const allPremiumPlans = async () => {
    let result = await getPremiumPackageAmountPlans()
    setPremiumPlan(result.data)
  }

  const createAdPlan = async () => {
    let result = await createAdsPlan(adsPlan)
    if (result.status === 200) {
      setAdsPlan({type: '', premiumPlanId: '', creditAndDays: '', adsType: ''})
      setIsEdit(false)
      getPlansList()
      ToastUtils({type: 'success', message: 'Ads Plan Created'})
    } else {
      ErrorToastUtils()
    }
  }

  const updateAdPlan = async () => {
    let result = await updateAdsPlan(adsPlan)
    if (result.status === 200) {
      setAdsPlan({type: '', premiumPlanId: '', creditAndDays: '', adsType: ''})
      setIsEdit(false)
      getPlansList()
      ToastUtils({type: 'success', message: 'Ads Plan Updated'})
    } else {
      ErrorToastUtils()
    }
  }

  const deleteAdPlan = async (planId: any) => {
    let result = await deleteAdsPlan(planId)
    if (result.status === 200) {
      getPlansList()
      ToastUtils({type: 'success', message: 'Ads Plan Deleted'})
    } else {
      ErrorToastUtils()
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Ads Planner</PageTitle>
      <div className='d-flex justify-content-end mb-4'>
        <button
          className='btn btn-primary'
          data-bs-toggle='modal'
          data-bs-target='#add_update_ads_plan'
        >
          Create
        </button>
      </div>
      <KTCardBody className='py-4 card'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <td>No.</td>
                <td>type</td>
                <td>Premium Plan Name</td>
                <td>Coins Or Days</td>
                <td>Count</td>
                <td>adsType</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody className='text-gray-600 '>
              {adsPlanList.length !== 0 &&
                adsPlanList.map((adsPlan: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span>{index + 1}</span>
                      </td>
                      <td>
                        <span>{adsPlan?.type || ' - '}</span>
                      </td>
                      <td>
                        <span>{adsPlan?.premiumPlanId || ' - '}</span>
                      </td>
                      <td>
                        <span>
                          {`${adsPlan?.creditAndDays} ${
                            adsPlan?.premiumPlanId == null || adsPlan?.premiumPlanId == 0
                              ? 'Credits'
                              : 'Days'
                          }` || ' - '}
                        </span>
                      </td>
                      <td>
                        <span>{adsPlan?.count || 0}</span>
                      </td>
                      <td>
                        <span>{adsPlan?.adsType || ' - '}</span>
                      </td>
                      <td>
                        <div className='d-flex my-4'>
                          <button
                            className='btn btn-primary'
                            onClick={() => {
                              setAdsPlan(adsPlan)
                              setIsEdit(true)
                            }}
                            data-bs-toggle='modal'
                            data-bs-target='#add_update_ads_plan'
                          >
                            <i className='bi bi-pencil-square'></i>
                          </button>
                          &nbsp;
                          <button
                            className='btn btn-danger'
                            onClick={() => {
                              deleteAdPlan(adsPlan.adsPlanId)
                            }}
                          >
                            <i className='bi bi-trash3'></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </KTCardBody>

      <div className='modal fade' tabIndex={-1} id='add_update_ads_plan'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{isEdit ? 'Update' : 'Add'} Plan</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => {
                  setAdsPlan({type: 'credit'})
                  setIsEdit(false)
                }}
              >
                <i className='fa-solid fa-xmark'></i>
              </div>
            </div>
            <div className='modal-body'>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-hide-search='true'
                value={adsPlan.type}
                onChange={(e) =>
                  setAdsPlan({
                    ...adsPlan,
                    type: e.target.value,
                  })
                }
              >
                <option value=''>Select Type</option>
                <option value='credit'>Coins</option>
                <option value='premium'>Premium</option>
              </select>
              <br />
              {adsPlan.type === 'premium' && (
                <>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-hide-search='true'
                    value={adsPlan.premiumPlanId}
                    onChange={(e) =>
                      setAdsPlan({
                        ...adsPlan,
                        premiumPlanId: e.target.value,
                      })
                    }
                  >
                    <option value=''>Select Plan</option>
                    {premiumPlan
                      //.filter((plan: any) => plan.forAdmin == false)
                      .map((plan: any) => {
                        return (
                          <option value={plan.premiumPackageAmountId}>
                            {plan.premiumPackageName}
                          </option>
                        )
                      })}
                  </select>
                  <br />
                </>
              )}

              <input
                placeholder='Enter Coins Or Days'
                type='number'
                className={'form-control form-control-solid mb-3 mb-lg-0'}
                autoComplete='off'
                value={adsPlan.creditAndDays}
                onChange={(e) =>
                  setAdsPlan({
                    ...adsPlan,
                    creditAndDays: e.target.value,
                  })
                }
              />
              <br />
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-hide-search='true'
                value={adsPlan.adsType}
                onChange={(e) =>
                  setAdsPlan({
                    ...adsPlan,
                    adsType: e.target.value,
                  })
                }
              >
                <option value=''>Select Ads Type</option>
                <option value='banner'>Banner</option>
                <option value='interstitial'>Interstitial</option>
                <option value='appOpen'>App Open</option>
                <option value='nativeAdvanced'>Native Advanced</option>
                <option value='rewarded'>Rewarded</option>
              </select>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                onClick={() => {
                  setAdsPlan({type: '', premiumPlanId: '', creditAndDays: '', adsType: ''})
                  setIsEdit(false)
                }}
              >
                Close
              </button>

              <button
                type='button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
                onClick={() => {
                  isEdit ? updateAdPlan() : createAdPlan()
                }}
              >
                {isEdit ? 'Update' : 'Add'} Plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdsPlanPlugin
