import React, {useEffect, useState} from 'react'
import {
  getConfigurationByName,
  updateConfigurationByConfigID,
} from '../../../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'

const GlobalOnlinePlugin = () => {
  const [configID, setConfigId] = useState(0)
  const [globalOnlineConfig, setGlobalOnlineConfig] = useState<any>({
    globalOnlineUserArea: 'city',
  })

  useEffect(() => {
    getConfiguration()
  }, [])

  const handleChange = (event: any) => {
    setGlobalOnlineConfig({globalOnlineUserArea: event.target.value})
    updateConfiguration({globalOnlineUserArea: event.target.value})
  }

  const getConfiguration = async () => {
    let result = await getConfigurationByName('global-online-users')
    if (result.success) {
      let parsedData = JSON.parse(result.data.values)
      setConfigId(result.data.id)
      setGlobalOnlineConfig({
        globalOnlineUserArea: parsedData.globalOnlineUserArea,
      })
    }
  }

  const updateConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(configID, config, null)
    if (result.success) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  return (
    <div className='card'>
      <div className='card-title p-8'>
        <h2>Global Online settings</h2>
      </div>
      <div className='card-body'>
        <div className='row no-gutters'>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Filter proximity range</strong>
            </p>
            <p className='text-muted'>Select which proximity range use to search</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='gender'
                data-hide-search='true'
                name='globalOnlineUserArea'
                value={globalOnlineConfig.globalOnlineUserArea}
                onChange={(event) => handleChange(event)}
              >
                <option value='country'>Country</option>
                <option value='state'>State</option>
                <option value='city'>City</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GlobalOnlinePlugin
