import React, {useEffect, useState} from 'react'
import {
  getConfigurationByName,
  updateConfigurationByConfigID,
} from '../../../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'

const WithDrawalPlugin = () => {
  const [configID, setConfigId] = useState(0)
  const [withdrawalConfig, setWithdrawalConfig] = useState<any>({
    isWithdraw: false,
    withDrawMinLimit: 0,
    withDrawDiamonds: 1000,
    withDrawGender: '1',
  })

  useEffect(() => {
    getConfiguration()
  }, [])

  const handleChange = (event: any) => {
    let name = event.target.name

    if (name !== 'isWithdraw' || name !== 'withDrawGender') {
      setWithdrawalConfig({...withdrawalConfig, [name]: event.target.value})
    } else {
      setWithdrawalConfig({...withdrawalConfig, [name]: event.target.checked})
      updateConfiguration({...withdrawalConfig, [name]: event.target.checked})
    }
  }

  const getConfiguration = async () => {
    let result = await getConfigurationByName('withdraw')
    if (result.success) {
      let parsedData = JSON.parse(result.data.values)
      setConfigId(result.data.id)
      setWithdrawalConfig({
        isWithdraw: parsedData.isWithdraw,
        withDrawMinLimit: parsedData.withDrawMinLimit,
        withDrawDiamonds: parsedData.withDrawDiamonds,
        withDrawGender: parsedData.withDrawGender,
      })
    }
  }

  const onBlurUpdate = () => {
    updateConfiguration(withdrawalConfig)
  }

  const updateConfiguration = async (config: any) => {
    let result = await updateConfigurationByConfigID(configID, config, null)
    if (result.success) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  return (
    <div className='card'>
      <div className='card-title p-8'>
        <h2>WithDrawal settings</h2>
      </div>
      <div className='card-body'>
        <div className='row no-gutters'>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Withdrawal system</strong>
            </p>
            <p className='text-muted'>Allow users to exchange credits for real money</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isWithdraw'
                checked={withdrawalConfig.isWithdraw}
                onChange={(event) => {
                  setWithdrawalConfig({
                    ...withdrawalConfig,
                    [event.target.name]: event.target.checked,
                  })
                  updateConfiguration({
                    ...withdrawalConfig,
                    [event.target.name]: event.target.checked,
                  })
                }}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Withdrawal Diamonds</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <input
                type='number'
                className='form-control'
                name='withDrawDiamonds'
                value={withdrawalConfig.withDrawDiamonds}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Withdrawal Required Amount</strong>
            </p>
            <p className='text-muted'>Set the minimum amount of money to request a withdrawal</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <input
                type='number'
                className='form-control'
                name='withDrawMinLimit'
                value={withdrawalConfig.withDrawMinLimit}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurUpdate}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Give Withdrawal Access to Selected Gender</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='gender'
                data-hide-search='true'
                defaultValue={withdrawalConfig.withDrawGender}
                name='withDrawGender'
                value={withdrawalConfig.withDrawGender}
                onChange={(event) => handleChange(event)}
                onBlur={onBlurUpdate}
              >
                <option value={'0'}>All Gender</option>
                <option value={'1'}>Male</option>
                <option value={'2'}>Female</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WithDrawalPlugin
