/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import CardWidget from './Componants/CardWidget'
import {ActivityTable} from './Componants/ActivityTable'
import StaticsCharts from './Componants/StaticsCharts'
import {getDashboardAnalytics} from '../../../API/api-endpoint'
import socketIO from 'socket.io-client'

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  const [dashboardData, setDashboardData] = useState<any>([])
  const [onlineUserCount, setOnlineUserCount] = useState(0)
  const [userCount, setUserCount] = useState<any>(undefined)
  const [revenue, setRevenue] = useState<any>({
    todayRevenue: 0,
    totalRevenue: 0,
    revenuePercentage: 0,
  })
  const [isWebSocketEnabled, setIsWebSocketEnabled] = useState(false)
  const [webSocketActivity, setWebSocketActivity] = useState<any>([])

  useEffect(() => {
    getAnalyticsData()
  }, [])

  const getAnalyticsData = async () => {
    let result = await getDashboardAnalytics()
    if (result.success) {
      setDashboardData(result.data)
    }
  }

  useEffect(() => {
    const socket = socketIO(process.env.REACT_APP_SERVER_URL || 'http://192.168.0.245:9091', {
      transports: ['websocket'],
      auth: {authorization: 'dashboard_token'},
    })

    // Listen for events
    socket.on('connect', () => {
      console.log('Connected using WebSocket transport')
    })

    socket.emit('userOnline')
    socket.emit('todayUser')
    socket.emit('revenue')

    socket.on('userOnline', (data) => {
      setOnlineUserCount(data.onlineUser)
    })

    socket.on('revenue', (data) => {
      setRevenue(data || 0)
    })

    socket.on('todayUser', (data) => {
      setUserCount(data || 0)
    })

    socket.on('userActivity', (data) => {
      webSocketActivity.unshift(data)
      setWebSocketActivity([...webSocketActivity])
    })

    return () => {
      socket.close()
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      {dashboardData !== undefined && (
        <>
          <div className='row'>
            <div className='col-md-4 col-lg-4 mb-5 mb-xl-10'>
              <CardWidget
                count={onlineUserCount}
                title='Online Users'
                percentage=''
                isPercentageShow={false}
                icon={
                  <i className='ki-duotone ki-abstract-36 fs-2x mb-5 ms-n1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                }
              />
            </div>

            <div className='col-md-4 col-lg-4 mb-5 mb-xl-10'>
              <CardWidget
                count={'₹ ' + revenue?.todayRevenue || 0}
                title='Todays Revenue'
                percentage={revenue?.revenuePercentage || 0}
                isPercentageShow={true}
                icon={<i className='ki-outline ki-chart-line-up-2 fs-2x'></i>}
              />
            </div>

            <div className='col-md-4 col-lg-4 mb-5 mb-xl-10'>
              <CardWidget
                count={userCount?.todaysNewUsers || 0}
                title='Todays New Users'
                percentage={userCount?.userPercentage || 0}
                isPercentageShow={true}
                icon={<i className='ki-outline ki-chart-simple fs-2x'></i>}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-5 col-lg-5 mb-5 mb-xl-10'>
              <StaticsCharts />
              <div className='row'>
                <div className='col-md-12 col-lg-12 mb-2 mb-xl-2'>
                  <CardWidget
                    count={'₹ ' + (dashboardData?.totalWithDrawalAmount || 0)}
                    title='Total Withdrawal Amount'
                    isPercentageShow={false}
                    icon={<i className='ki-outline ki-chart-simple fs-2x '></i>}
                  />
                </div>
                <div className='col-md-6 col-lg-6 mb-2 mb-xl-2'>
                  <CardWidget
                    count={userCount?.totalUsers || 0}
                    title='Total Users'
                    isPercentageShow={false}
                    icon={<i className='ki-outline ki-people fs-2x'></i>}
                  />
                </div>

                <div className='col-md-6 col-lg-6 mb-2 mb-xl-2'>
                  <CardWidget
                    count={'₹ ' + revenue?.totalRevenue ? revenue?.totalRevenue : 0}
                    title='Total Revenue'
                    isPercentageShow={false}
                    icon={<i className='ki-outline ki-chart-simple fs-2x '></i>}
                  />
                </div>
              </div>
            </div>

            <div className='col-md-7 col-lg-7 mb-5 mb-xl-10'>
              <ActivityTable
                isWebSocketEnabled={isWebSocketEnabled}
                setIsWebSocketEnabled={setIsWebSocketEnabled}
                webSocketActivity={webSocketActivity}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export {DashboardWrapper}
